<template>
  <CardBase
    :title="$t('food_sales_daily_title')"
    additional-badge="F"
    additional-badge-color="#E8FFE0"
    additional-badge-text-color="#38761D"
    :help-button="false"
    :custom-date="date"
    route-destination="/sales-costs"
    :route-text="$t('sales_and_costs_daily_route_text')"
  >
    <template v-slot:content-body>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-slide-group
            show-arrows="always"
            v-model="chosenMonth"
            mobile-breakpoint="0"
            center-active
          >
            <v-slide-item
              v-for="(item, index) in months"
              :key="index"
              v-slot="{ active, toggle }"
              :value="item.id"
            >
              <v-btn
                active-class="font-weight-bold text-heading-3"
                :input-value="active"
                small
                text
                @click="toggle"
                plain
                :ripple="false"
                :disabled="loading"
              >
                {{ item.name }}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </v-col>

        <v-col cols="12">
          <v-card class="current-balance-card rounded-0">
            <v-card-text>
              <v-row
                align="center"
                class="font-weight-bold main--text py-4 text-center"
              >
                <v-col cols="5">
                  <div class="text-heading-3">
                    {{ $t("month_total", { month: month }) }}
                  </div>
                </v-col>

                <v-col cols="auto">
                  <div class="text-heading-8">
                    {{ getSalesCostsDailyFoodData.total_cost | toCurrency }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <TableDailyFoodCost :items="salesAndCosts" :loading="loading" />
        </v-col>
      </v-row>
    </template>
  </CardBase>
</template>

<script>
import CardBase from "@/components/ui/CardBase";
import TableDailyFoodCost from "@/components/ui/TableDailyFoodCost";
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";
export default {
  name: "DailyFoodRelatedReportCard",
  components: { TableDailyFoodCost, CardBase },
  created() {
    this.mapMonths();
  },
  computed: {
    ...mapGetters(["getUserReport", "getSalesCostsDailyFoodData"]),
    date() {
      return dayjs(this.getSalesCostsDailyFoodData.date).format("YYYY-MM-DD");
    },
    month() {
      return this.chosenMonth.split("-")[1];
    }
  },
  data() {
    return {
      loading: false,
      chosenMonth: 0,
      months: [],
      salesAndCosts: []
    };
  },
  watch: {
    chosenMonth: function(newVal) {
      let year = newVal.split("-")[0];
      let month = newVal.split("-")[1];
      this.getDataFromApi({ month: month, year: year });
    }
  },
  methods: {
    getDataFromApi(param) {
      this.loading = true;
      this.$store
        .dispatch("SALES_COSTS_DAILY_FOOD_GET", param)
        .then(() => {
          let salesAndCosts = [];
          for (const item of this.getSalesCostsDailyFoodData.deals) {
            salesAndCosts.push({
              date: dayjs(item.date).format("YYYY-MM-DD"),
              day: dayjs(item.date).day(),
              costs: item.cost,
              partner: item.partner?.name
            });
          }
          this.salesAndCosts = salesAndCosts;
        })
        .finally(() => (this.loading = false));
    },
    mapMonths() {
      if (this.$route.params.month) {
        this.chosenMonth = this.$route.params.month;
      } else {
        this.chosenMonth = this.getUserReport.monthly_deals[
          this.getUserReport.monthly_deals.length - 1
        ].month.id;
      }
      this.months = this.getUserReport.monthly_deals.map(item => {
        return {
          id: item.month.id,
          name: item.month.name
        };
      });
    }
  }
};
</script>

<style scoped>
.current-balance-card {
  background-color: #edffeb !important;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.15) !important;
}
</style>
