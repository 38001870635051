<template>
  <v-container fluid class="px-0">
    <DailyFoodRelatedReportCard />
  </v-container>
</template>

<script>
import DailyFoodRelatedReportCard from "@/components/layout/sales-and-costs/DailyFoodRelatedReportCard";
export default {
  name: "DailyFoodCost",
  components: { DailyFoodRelatedReportCard }
};
</script>

<style scoped></style>
